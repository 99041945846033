import React from "react"
import Container from 'react-bootstrap/Container'
import Layout from "../components/Layout/Layout"

const NotFoundPage = () => (
  <Layout
    title="Page Not Found">
    <Container className="section-container text-center">
      <h1>Page Not Found</h1>
      <p>I'm sorry. The page you requested is no longer available.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
